<template>
  <div class="admin-table">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h1 class="admin-title mb-0">{{$t(title)}}</h1>
      <div class="d-flex">
        <RFButton label="Update" size="small" background="#24425B" @on-click="$emit('update-list')"/>
        <RFButton v-if="availabilityButton" label="Show availability" size="small" background="#24425B" @on-click="$emit('show-availability')"/>
        <JsonCSV v-if="exportButton"
          :data="list"
          :fields="getFields()"
          :name="getCsvName()">
          <RFButton label="Export to CSV" size="small" background="#24425B"/>
        </JsonCSV>
      </div>
    </div>

    <div class="payments-table-filters">
      <ValidationObserver ref="validator">
        <form @submit.prevent>
          <b-row>
            <b-col>
              <RFValidation name="Firstname">
                <RFInput label="Firstname" v-model="filter.firstname"/>
              </RFValidation>
            </b-col>
            <b-col>
              <RFValidation name="Lastname">
                <RFInput label="Lastname" v-model="filter.lastname"/>
              </RFValidation>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <RFValidation name="Payment type">
                <RFInput label="Payment type" v-model="filter.paymentType"/>
              </RFValidation>
            </b-col>
            <b-col>
              <RFValidation name="Payment status">
                <RFInput label="Payment status" v-model="filter.paymentStatus"/>
              </RFValidation>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <RFValidation name="Unit name">
                <RFInput label="Unit name" v-model="filter.unitName"/>
              </RFValidation>
            </b-col>
            <b-col>
              <RFValidation name="Email">
                <RFInput label="Email" v-model="filter.email"/>
              </RFValidation>
            </b-col>
          </b-row>
          <b-row class="justify-content-end">
            <b-col/>
            <b-col/>
            <b-col class="text-center">
              <RFButton class="mt-3" label="Clear" background="#24425B" v-if="!loading"  @on-click="clearFilters"/>
              <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
            </b-col>
            <b-col class="text-center">
              <RFButton class="mt-3" label="Search" background="#24425B" v-if="!loading" @on-click="search"/>
              <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
            </b-col>
            <b-col/>
            <b-col/>
          </b-row>
        </form>
      </ValidationObserver>
    </div>

    <b-table class="mt-5" responsive hover sticky-header :items="list" :fields="fields" :filter="filterToSearch" :filter-function="filterFunction"
       @filtered="onFiltered"  :per-page="perPage" :current-page="currentPage" v-show="resultList.length && !loading">
      <template v-slot:head()="data">
        <div class="table-label">
          <template v-if="data.field.flag">
            <flag :squared="false" :iso="data.field.flag"/>
          </template>
          {{data.label}}
        </div>
      </template>

      <template v-slot:cell()="data">
        <div class="table-data">
          <template v-if="data.field.key === 'status'">
            <div class="status" :class="{
              new: data.value === userStatus.NEW || data.value === reservationStatus.NEW,
              registered: data.value === userStatus.REGISTERED,
              'profile-complete': data.value === userStatus.PROFILE_COMPLETE,
              'check-document': data.value === userStatus.CHECK_DOCUMENT,
              'in-progress': data.value === ticketStatus.IN_PROGRESS,
              closed: data.value === ticketStatus.CLOSED,
              verified: data.value === userStatus.VERIFIED,
              refused: data.value === reservationStatus.REFUSED,
              accepted: data.value === reservationStatus.ACCEPTED,
              completed: data.value === reservationStatus.COMPLETED,
              active: data.value === reservationStatus.ACTIVE,
              ok: data.value === paymentStatus.OK,
              ko: data.value === paymentStatus.KO,
              scheduled: data.value === paymentStatus.SCHEDULED,
              pending: data.value === paymentStatus.PENDING,
            }">{{data.value | replaceUnderscore | toUpperCase }}</div>
          </template>
          <template v-else-if="data.field.type === 'date'">
            {{data.value | formatDate}}
          </template>
          <template v-else-if="data.field.key === 'paymentType'">
            <span>{{$t(getPaymentType(data.value))}}</span>
          </template>
          <template v-else-if="data.field.type === 'cash'">
            &euro; {{data.value}}
          </template>
          <template v-else-if="data.field.type === 'boolean'">
            <FontAwesomeIcon :icon="['fas', 'check']" :color="'#5CB85C'" v-if="data.value"/>
          </template>
          <template v-else-if="data.field.type === 'actions'">
            <div class="d-inline" v-for="(action, index) in data.field.actions" :key="`action-${index}`">
              <FontAwesomeIcon v-if="action === 'add'" class="cursor-pointer rf-success-color mx-1" :icon="['fas', 'plus']" @click="$emit('add-related-item', data.item)"
                               data-toggle="tooltip" data-placement="top" title="Add"/>
              <FontAwesomeIcon v-else-if="action === 'view'" class="cursor-pointer rf-blue-color mx-1" :icon="['fas', 'eye']" @click="$emit('show-related-items', data.item)"
                               data-toggle="tooltip" data-placement="top" title="View"/>
              <FontAwesomeIcon v-else-if="action === 'edit'" class="cursor-pointer mx-1" :icon="['fas', 'pen']" @click="editItem(data.item)"
                               data-toggle="tooltip" data-placement="top" title="Edit"/>
              <FontAwesomeIcon v-else-if="action === 'show'" class="cursor-pointer mx-1" :icon="['fas', 'search']" :id="data" @click.stop="$emit('open-modal', data.item)"
                               data-toggle="tooltip" data-placement="top" title="Show more"/>
<!--              <FontAwesomeIcon v-else-if="action === 'calendar'" class="cursor-pointer mx-1" :icon="['fas', 'calendar']" @click.stop="$emit('calendar', data.item)"/>-->
              <FontAwesomeIcon v-else-if="action === 'download' && data.item.documents !== null" class="cursor-pointer mx-1" :icon="['fas', 'download']" @click.stop="$emit('download', data.item)"
                               data-toggle="tooltip" data-placement="top" title="Download contract"/>
              <template v-else-if="action === 'delete'">
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'trash']" color="#FF5A5F" @click="selectItem(data.item)" v-if="((selectedItem && selectedItem.id !== data.item.id) || !confirm) && !data.item.deleted"
                                 data-toggle="tooltip" data-placement="top" title="Delete"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'trash-restore']" color="#24425B" @click="selectItem(data.item)" v-if="data.item.deleted && !confirm"
                                 data-toggle="tooltip" data-placement="top" title="Restore"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'check']" color="#5CB85C" @click="data.item.deleted ? restoreItem() : deleteItem()" v-if="confirm && (selectedItem && selectedItem.id === data.item.id)"
                                 data-toggle="tooltip" data-placement="top" title="Confirm"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'times']" color="#FF5A5F" @click="confirm = false" v-if="confirm && (selectedItem && selectedItem.id === data.item.id)"
                                 data-toggle="tooltip" data-placement="top" title="Cancel"/>
              </template>
              <template v-else-if="action === 'bt' && data.item.status === 'ACCEPTED' && data.item.payments.length === 0">
                <FontAwesomeIcon  class="cursor-pointer rf-blue-color mx-1" :icon="['fas', 'money-bill']" @click="selectItem(data.item)"
                                  data-toggle="tooltip" data-placement="top" title="Set bank transfer as payment method"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'check']" color="#5CB85C" @click="setBankTransfer()" v-if="confirm && (selectedItem && selectedItem.id === data.item.id)"
                                 data-toggle="tooltip" data-placement="top" title="Confirm"/>
                <FontAwesomeIcon class="cursor-pointer mx-1" :icon="['fas', 'times']" color="#FF5A5F" @click="confirm = false" v-if="confirm && (selectedItem && selectedItem.id === data.item.id)"
                                 data-toggle="tooltip" data-placement="top" title="Cancel"/>
              </template>
            </div>
          </template>
          <template v-else>{{data.value}}</template>
        </div>
      </template>
    </b-table>
    <b-pagination
      class="mt-4"
      v-if="!loading && resultList.length > perPage"
      v-model="currentPage"
      :total-rows="resultList.length"
      :per-page="perPage" />
    <div class="admin-loading" v-if="loading">
      <RFLoader class="mr-3" color="#24425B" :size="30" /> {{$t('Uploading...')}}
    </div>
    <div class="empty-list" v-if="!loading && !resultList.length">
      {{$t('There are no items.')}}
    </div>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import JsonCSV from 'vue-json-csv'
import { formatDate, replaceUnderscore, toUpperCase } from '@/helpers/filters'
import RFValidation from '@/components/forms/RFValidation'
import RFInput from '@/components/forms/RFInput'

import { reservationStatus, userStatus, paymentStatus, ticketStatus } from '@/enums'
import {mapState} from "vuex";

export default {
  components: {
    RFButton,
    RFLoader,
    JsonCSV,
    RFValidation,
    RFInput
  },
  props: {
    list: Array,
    fields: Array,
    toExportFields: {
      type: Array,
      default: null,
    },
    title: String,
    loading: Boolean,
    type: String,
    perPage: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
    hideRelatedItems: {
      type: Boolean,
      default: false,
    },
    hideOpenModal: {
      type: Boolean,
      default: false,
    },
    availabilityButton: {
      type: Boolean,
      default: false,
    },
    exportButton: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      userStatus,
      reservationStatus,
      paymentStatus,
      ticketStatus,

      filter: {
        firstname: null,
        lastname: null,
        paymentType: null,
        paymentStatus: null,
        unitName:null,
        email: null
      },
      filterToSearch: null,
      resultList: [],

      selectedItem: null,
      confirm: false,
    }
  },
  computed: {
    ...mapState({
      filterData: state => state.adminPaymentStore.paymentsFilterData
    }),
  },
   beforeMount() {
    this.resultList = this.list
     // Check if filterData is null. If it is, it works normally (without filters). Otherwise, we get the filters and current page previously used
     if(this.filterData!=null){
       this.filter = Object.assign({}, this.filterData.filter);
       this.currentPage = this.filterData.currentPage
       this.search()
     }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      let newCurrentPage = 1
      if(this.filterData!=null){
        newCurrentPage = this.filterData.currentPage
        this.$store.commit('adminPaymentStore/setPaymentsFilterData', null)
      }
      this.currentPage = newCurrentPage
      this.resultList = filteredItems
    },
    selectItem(item) {
      this.selectedItem = item
      this.confirm = true
    },
    deleteItem() {
      this.confirm = false
      //Save filterData in store and emit current event
      this.saveFiltersToStore()
      this.$emit('delete', this.selectedItem)
    },
    restoreItem(item) {
      this.confirm = false
      //Save filterData in store and emit current event
      this.saveFiltersToStore()
      this.$emit('restore', this.selectedItem)
    },
    getFields() {
      return this.toExportFields ? this.toExportFields : this.fields.map(f => f.key)
    },
    getCsvName() {
      return `${this.title.replaceAll(' ', '')}-${new Date().toISOString()}.csv`
    },
    setBankTransfer() {
      this.confirm = false
      //Save filterData in store and emit current event
      this.saveFiltersToStore()
      this.$emit('set-bank-transfer', this.selectedItem)
    },
    editItem(item){
      //Save filterData in store and emit current event
      this.saveFiltersToStore()
      this.$emit('edit', item)
    },
    search(){
      this.filterToSearch = Object.assign({}, this.filter);
    },
    filterFunction(row,filter){

      //Check firstname filter
      let firstnameFilter = filter.firstname==null || (row.firstname !== null && row.firstname.toLowerCase().includes(filter.firstname.toLowerCase()))
      //Check lastname filter
      let lastnameFilter = filter.lastname==null || (row.lastname !== null && row.lastname.toLowerCase().includes(filter.lastname.toLowerCase()))
      //Check payment type filter
      let paymentTypeFilter = filter.paymentType==null || this.$i18n.t(row.paymentType !== null && this.getPaymentType(row.paymentType)).toLowerCase().includes(filter.paymentType.toLowerCase())
      //Check payment status filter
      let paymentStatusFilter = filter.paymentStatus==null || (row.status !== null && row.status.toLowerCase().includes(filter.paymentStatus.toLowerCase()))
      //Check unit name filter
      let unitNameFilter = filter.unitName==null || (row.name !== null && row.name.toLowerCase().includes(filter.unitName.toLowerCase()))
      //Check email filter
      let emailFilter = filter.email==null || ( row.email !== null && row.email.toLowerCase().includes(filter.email.toLowerCase()))

      return firstnameFilter && lastnameFilter && paymentTypeFilter && paymentStatusFilter && unitNameFilter && emailFilter

    },
    clearFilters(){
      //Clear all the filters
      Object.keys(this.filter).forEach(key=>{
        this.filter[key] = null
      })
      //Call search method
      this.search()
    },
    getPaymentType(key){
      if(key==='CC'){
        return 'Credit Card'
      }
      return 'Bank Transfer'
    },
    saveFiltersToStore(){
      //Save filterData in store (filter and currentPage)
      let filterData = {
        filter: this.filterToSearch == null ? this.filter : this.filterToSearch,
        currentPage: this.currentPage
      }
      this.$store.commit('adminPaymentStore/setPaymentsFilterData', filterData)
    }
  },
  filters: {
    formatDate,
    replaceUnderscore,
    toUpperCase
  }
}
</script>

<style lang="scss" scoped>
.table.label{
  padding-top: 10px;
}


.admin-table {
  .table-label {
    @include font-style($montserrat-bold, 'medium', $font-12);
  }
}
</style>
