<template>
  <div class="all-payments">
    <PaymentsTable title="All Payments" :list="paymentList" :fields="fields" :loading="loading"
    @update-list="update" />
    <!-- <div class="d-flex align-items-center justify-content-between mb-4">
      <h1 class="admin-title m-0">{{$t('All Payments')}}</h1>
      <RFButton label="Update" size="small" background="#24425B" @on-click="update"/>
    </div>
    <b-table responsive hover sticky-header :items="paymentList" :fields="fields" :per-page="perPage"
      :current-page="currentPage" v-if="paymentList.length && !loading">
      <template v-slot:head()="data">
        <div class="table-label">{{data.label}}</div>
      </template>
      <template v-slot:cell()="data">
        <div class="table-data">
          <template v-if="data.field.key === 'isRecurrent'">
            <FontAwesomeIcon :icon="data.value ? ['fas', 'check'] : ['fas', 'times']" :color="data.value ? '#5CB85C' : '#FF5A5F'" />
          </template>
          <template v-else-if="data.field.key === 'paymentType'">
            <span v-if="data.value === 'CC'">{{$t('Credit Card')}}</span>
            <span v-else>{{$t('Bank Transfer')}}</span>
          </template>
          <template v-else-if="data.field.key === 'amount'">
            &euro; {{data.value}}
          </template>
          <template v-else-if="data.field.key === 'status'">
            <span class="status"
            :class="{
              ok: data.value === paymentStatus.OK,
              ko: data.value === paymentStatus.KO,
              scheduled: data.value === paymentStatus.SCHEDULED,
              pending: data.value === paymentStatus.PENDING,
            }">{{data.value}}</span>
          </template>
          <template v-else>
            {{data.value}}
          </template>
        </div>
      </template>
    </b-table>
    <b-pagination
      class="mt-4"
      v-if="!loading && paymentList.length > perPage"
      v-model="currentPage"
      :total-rows="paymentList.length"
      :per-page="perPage" />
    <div class="admin-loading" v-if="loading">
      <RFLoader class="mr-3" color="#24425B" :size="30" /> {{$t('Uploading...')}}
    </div>
    <div class="empty-list" v-if="!paymentList.length && !loading">
      {{$t('There are no payments.')}}
    </div> -->
  </div>
</template>

<script>
import PaymentsTable from '@/components/admin/PaymentsTable'
// import RFButton from '@/components/forms/RFButton'
// import RFLoader from '@/components/forms/RFLoader'
import { Payment } from '@/models/payment.js'

import { adminService } from '@/services/admin'
import { paymentStatus } from '@/enums'

export default {
  name: 'AdminPaymentList',
  components: {
    PaymentsTable,
    // RFButton,
    // RFLoader,
  },
  data() {
    return {
      paymentStatus,

      currentPage: 1,
      perPage: 10,
      paymentList: [],
      loading: false,
    }
  },
  computed: {
    fields() {
      return [
        { label: 'id', key: 'id' },
        { label: 'Contract code', key: 'codiceContratto' },
        { label: 'Description', key: 'description' },
        { label: 'Recurrent', key: 'isRecurrent', type: 'boolean' },
        { label: 'Payment type', key: 'paymentType' },
        { label: 'Firstname', key: 'firstname' },
        { label: 'Lastname', key: 'lastname' },
        { label: 'Email', key: 'email' },
        { label: 'Unit Name', key: 'name' },
        { label: 'Amount', key: 'amount', type: 'cash' },
        { label: 'Status', key: 'status' },
      ]
    }
  },
  async beforeMount() {
    await this.update()
  },
  methods: {
    async update() {
      this.loading = true
      this.paymentList = []
      const auxList = await adminService.getPaymentList()
      await Promise.all(
        auxList.map(async (element, index) => {
          this.paymentList.push(element)
        })
      )

      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.all-payments {
  .status {
    border-radius: 20px;
    text-align: center;
    color: $white;
    white-space: nowrap;
    text-transform: uppercase;
    padding: 0 5px;

    &.ok {
      background-color: $success;
    }
    &.ko {
      background-color: $danger;
    }
    &.scheduled {
      background-color: $green;
    }
    &.pending {
      background-color: $dark-blue;
    }
  }
}
</style>
